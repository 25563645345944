import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

import extractError from '@myservice/helper/extractError';
import {useNotificationMethod} from '@myservice/hooks';

import axiosInstance from '../apis';
import {ORDER, HIRE} from '../apis/allApis';

const OrderContext = createContext();
const OrderActionsContext = createContext();

const OrderProvider = ({children}) => {
  const [orderLoading, setOrderLoading] = useState(false);
  const {showNotification} = useNotificationMethod();

  const [numParticipants, setNumParticipants] = useState({});
  const [area, setArea] = useState({});

  const [createOrder, setCreateOrder] = useState({
    orderData: [],
    worker: {}
  });

  const [dateTime, setDateTime] = useState({
    date: null,
    time: {start: null, end: null}
  });
  const [dateRange, setDateRange] = useState({start: null, end: null});

  const [orders, setOrders] = useState({
    workerOrders: [],
    workerHiring: [],
    hiring: [],
    allUserOrder: [],
    count: 0,
    userHiring: []
  });

  const handleCreateOrder = (dish, worker) => {
    setCreateOrder((prevState) => {
      const isDishAlreadyInOrder = prevState.orderData.some(
        (selectedDish) => selectedDish.id === dish.id
      );

      if (isDishAlreadyInOrder) {
        return {
          ...prevState,
          worker: worker
        };
      }

      return {
        ...prevState,
        orderData: [...prevState.orderData, dish],
        worker: worker
      };
    });
  };

  const updateQuantity = (itemId, newQuantity) => {
    setCreateOrder((prevState) => ({
      ...prevState,
      orderData: prevState.orderData.map((item) =>
        item.id === itemId ? {...item, quantity: newQuantity} : item
      )
    }));
  };

  const removeItem = (itemId) => {
    setCreateOrder((prevState) => ({
      ...prevState,
      orderData: prevState.orderData.filter((item) => item.id !== itemId)
    }));
  };

  const fetchOrder = async (url, method = 'GET', payload = null) => {
    try {
      setOrderLoading(true);
      const response = await axiosInstance({
        method,
        url,
        data: payload
      });
      setOrderLoading(false);
      return response.data;
    } catch (error) {
      setOrderLoading(false);
      showNotification(extractError(error.response.data));
      console.error(error);
      throw error;
    }
  };

  const getAllUserOrder = async (page, page_size) => {
    const URL = `${ORDER}get-orders-by-user/?page=${page}&page_size=${page_size}`;
    const data = await fetchOrder(URL);

    setOrders((prev) => ({
      ...prev,
      allUserOrder: data?.results,
      count: data?.count
    }));
  };

  const getAllOrder = async (page, page_size) => {
    const URL = `${ORDER}?page=${page}&page_size=${page_size}`;
    return await fetchOrder(URL);
  };

  const getOrderDetails = async (orderNum) => {
    return await fetchOrder(`${ORDER}${orderNum}/get-user-order-details/`);
  };

  const postOrder = async (payload) => {
    return await fetchOrder(ORDER, 'POST', payload);
  };

  const getWorkerOrders = async (uuid) => {
    const data = await fetchOrder(
      `${ORDER}get-orders-by-worker?worker_id=${uuid}`
    );

    setOrders((prev) => ({
      ...prev,
      workerOrders: data
    }));
  };

  const getAllWorkerOrders = async () => {
    const data = await fetchOrder(`${ORDER}get-all-worker-order`);
    setOrders((prev) => ({
      ...prev,
      workerOrders: data
    }));
  };

  const postHire = async (payload) => {
    const data = await fetchOrder(HIRE, 'POST', payload);
    return data;
  };

  const getHiringDetails = async (hiringNum) => {
    return await fetchOrder(`${HIRE}${hiringNum}/get_hire_details/`);
  };

  const getAllHire = async () => {
    const data = await fetchOrder(`${HIRE}`);
    setOrders((prev) => ({
      ...prev,
      hiring: data
    }));
  };

  const getUserHire = async () => {
    const data = await fetchOrder(`${HIRE}user-hires/`);
    setOrders((prev) => ({
      ...prev,
      userHiring: data
    }));
  };

  const getWorkerHire = async (uuid) => {
    const data = await fetchOrder(
      `${HIRE}get-hires-by-worker?worker_id=${uuid}`
    );
    setOrders((prev) => ({
      ...prev,
      workerHiring: data
    }));
  };

  const updateHiringStatus = async (payload) => {
    return await fetchOrder(
      `${HIRE}${payload.hiring_number}/update-status/`,
      'PATCH',
      payload
    );
  };

  const updateOrderStatus = async (payload) => {
    return await fetchOrder(
      `${ORDER}${payload.order_number}/update-status/`,
      'PATCH',
      payload
    );
  };

  const getDestinationImage = async (uuid) => {
    return await fetchOrder(
      `${ORDER}destination-image/?itinerary_uuid=${uuid}`
    );
  };

  return (
    <OrderContext.Provider
      value={{
        ...orders,
        orderLoading,
        createOrder,
        dateRange,
        dateTime,
        area,
        numParticipants
      }}
    >
      <OrderActionsContext.Provider
        value={{
          getUserHire,
          postOrder,
          postHire,
          getAllHire,
          getWorkerHire,
          getWorkerOrders,
          getAllUserOrder,
          getAllOrder,
          getOrderDetails,
          handleCreateOrder,
          updateQuantity,
          removeItem,
          setCreateOrder,
          setDateRange,
          setDateTime,
          setNumParticipants,
          updateHiringStatus,
          getHiringDetails,
          updateOrderStatus,
          getDestinationImage,
          getAllWorkerOrders,
          setArea
        }}
      >
        {children}
      </OrderActionsContext.Provider>
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => useContext(OrderContext);
export const useOrderActionContext = () => useContext(OrderActionsContext);

export default OrderProvider;

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired
};
