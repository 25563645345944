import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {useNotificationMethod} from '@myservice/hooks/useNotification';
import * as api from '../apis/allApis';
import axiosInstance, {setAuthToken} from '../apis';
import extractError from '@myservice/helper/extractError';
import {determineSignUpField} from '@myservice/helper';

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

const JWTAuthAuthProvider = ({children}) => {
  const {showNotification} = useNotificationMethod();

  const [jwtAuthData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: false
  });

  const signUpUser = async (userInfo) => {
    setAuthToken();
    setJWTAuthData(() => ({
      ...jwtAuthData,
      isLoading: true
    }));

    const signUpPayload = determineSignUpField({...userInfo});

    try {
      if (signUpPayload?.phone_number) {
        const resp = await axiosInstance.post(
          api.NEW_USER_REGISTER,
          signUpPayload
        );
        setJWTAuthData(() => ({
          ...jwtAuthData,
          isLoading: false
        }));
        resp?.status === 201 &&
          window.location.replace('/on-boarding?auth=login', {replace: true});

        // window.location.replace('/otp-verfication', {replace: true});
      } else {
        const resp = await axiosInstance.post(
          api.SIGNUP_ACCOUNT,
          signUpPayload
        );

        setJWTAuthData(() => ({
          ...jwtAuthData,
          isLoading: false
        }));
        resp?.status === 201 &&
          window.location.replace('/email-sent-confirm', {replace: true});
      }
    } catch (error) {
      showNotification(
        extractError(error.response.data),
        'error',
        'Please Check for Error'
      );
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false
      });
    }
  };

  const signInUser = async (credentials) => {
    setAuthToken();
    setJWTAuthData(() => ({
      ...jwtAuthData,
      isLoading: true
    }));
    try {
      const {data} = await axiosInstance.post(api.SIGNIN_USER, credentials);
      setAuthToken(data?.access_token, data?.refresh_token);
      return await getUserMe();
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false
      });
      showNotification(error.response?.data?.detail);
    }
  };

  const updateUser = async (userInfo) => {
    try {
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: true
      }));
      const resp = await axiosInstance.patch(api.USER_ME, userInfo, {
        headers: {'Content-Type': 'multipart/form-data'}
      });

      getUserMe();
      return resp;
    } catch (error) {
      showNotification(
        extractError(error.response?.data),
        'error',
        'Please Check for Error'
      );
      setJWTAuthData({
        ...jwtAuthData,
        isLoading: false
      });
    }
  };

  const changePassword = async (credentails) => {
    try {
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: true
      }));
      const res = await axiosInstance.post(api.SET_PASSWORD, credentails);
      if (res) {
        showNotification('Password has been updated', 'info');
      }
      return res;
    } catch (error) {
      console.error(error.response.data);
      showNotification(error.response.data);
      setJWTAuthData({
        ...jwtAuthData,
        isLoading: false
      });
    }
  };

  const forgotPassword = async (credentails) => {
    try {
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: true
      }));
      const resp = await axiosInstance.post(api.RESET_PASSWORD, credentails);
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: false
      }));
      showNotification(
        'You have successfully reset your password you can now login with new password'
      );
      return resp;
    } catch (error) {
      console.error(error.response.data);
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false
      });
    }
  };

  const resetChangePassword = async (credentails) => {
    try {
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: true
      }));
      const resp = await axiosInstance.post(
        api.RESET_PASSWORD_CONFIRM,
        credentails
      );
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: false
      }));
      showNotification(
        'Your account password has been reset successfully. You can now utilize your new password to log in.',
        'info'
      );

      return resp;
    } catch (error) {
      console.error(error.response.data);
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false
      });
    }
  };

  // Function to call Google API for authentication
  const callGoogleApi = async (credentials) => {
    try {
      setAuthToken();
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: true
      }));
      const {data} = await axiosInstance.post(api.GOOGLE_OAUTH, credentials);
      setAuthToken(data?.access, data?.refresh);
      getUserMe();
    } catch (error) {
      showNotification(error.response.data?.detail);
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false
      });
    }
  };

  const validateEmail = async (payload) => {
    try {
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: true
      }));
      const resp = await axiosInstance.post(api.ACTIVATE, payload);
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: false
      }));
      return resp;
    } catch (error) {
      setJWTAuthData(() => ({
        ...jwtAuthData,
        isLoading: false
      }));
      console.error('Error while activating the email:', error);
    }
  };

  const logout = async () => {
    setAuthToken();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false
    });
    window.location.replace('/on-boarding');
  };

  const getUserMe = async () => {
    setJWTAuthData({
      ...jwtAuthData,
      isLoading: true
    });
    try {
      const {data} = await axiosInstance.get(api.USER_ME);
      setJWTAuthData({
        user: data,
        isAuthenticated: true,
        isLoading: false
      });
    } catch (error) {
      setJWTAuthData({
        ...jwtAuthData,
        isAuthenticated: false,
        isLoading: false
      });
    }
  };

  const getAPIKeys = async (key) => {
    const {data} = await axiosInstance.get(`${api.CREDENTIALS}${key}`);
    return data;
  };

  useEffect(() => {
    const access = localStorage.getItem('access');
    if (access) {
      setAuthToken(access);
      getUserMe();
    }
  }, []);

  return (
    <JWTAuthContext.Provider value={{...jwtAuthData}}>
      <JWTAuthActionsContext.Provider
        value={{
          callGoogleApi,
          getAPIKeys,
          signUpUser,
          logout,
          signInUser,
          validateEmail,
          updateUser,
          changePassword,
          forgotPassword,
          resetChangePassword,
          getUserMe
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
