import {useWorkers, useWorkerActions} from 'apps/worker/WorkerProvider';
import {workerInitialFormat, serviceForm} from '../helper';
import {useAuthUser} from './AuthUser';

export const useWorker = () => {
  const {
    isLoading,
    worker,
    workers,
    workerById,
    bussiness,
    searchParam,
    chefDish,
    currWorker
  } = useWorkers();

  const {user} = useAuthUser();
  return {
    isLoading,
    chefDish,
    worker,
    workerById,
    workers,
    searchParam,
    bussiness,
    currWorker,
    starterForm: serviceForm(worker, user),
    initailForm: workerInitialFormat(workerById)
  };
};

export const useWorkerMethod = () => {
  const {
    getPackages,
    switchAccount,
    postWorkerData,
    setCurrWorker,
    getWorkerData,
    getWorkerById,
    retrieveWorker,
    getWorkersData,
    setSearchParam,
    getAllBussiness,
    getFeaturePackages,
    getFeaturePainters,
    getFeatureTravelTours
  } = useWorkerActions();
  return {
    getPackages,
    switchAccount,
    setCurrWorker,
    getWorkerData,
    getWorkerById,
    postWorkerData,
    retrieveWorker,
    getWorkersData,
    setSearchParam,
    getAllBussiness,
    getFeaturePainters,
    getFeaturePackages,
    getFeatureTravelTours
  };
};
