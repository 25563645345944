import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes, css} from 'styled-components';
import {logo, profile} from 'assests/images';
import {useNavigate} from 'react-router-dom';
import Hamburger from '@myservice/utility/Hamburger';
import {ChevronLeft, x} from 'assests/icons';

export const MyServiceLogo = ({font, title, parmLogo, imgSize, isPrimary}) => {
  const navigate = useNavigate();
  return (
    <LogoWrapper
      onClick={() => navigate('/')}
      imgSize={imgSize}
      $isPrimary={isPrimary}
    >
      <img src={parmLogo ?? logo} alt='myservice' loading='lazy' />
      <LogoFont font={font}> {title ?? 'myservice'} </LogoFont>
    </LogoWrapper>
  );
};

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({$isPrimary}) => ($isPrimary ? '26rem' : '12rem')};
  max-width: 100%;
  z-index: 1;
  > img {
    width: ${({imgSize}) => (imgSize ? imgSize : '1.45rem')};
    margin-inline-end: 0.25rem;
  }
`;

const LogoFont = styled.h4`
  font-family: ${({theme}) => theme.font};
  font-size: ${({font}) => (font ? font : '1.25rem')};
  font-weight: 500;
  color: ${({theme}) => theme.clr[400]};
  letter-spacing: -0.09rem;
  cursor: pointer;
`;

const SvgIcon = styled.svg`
  cursor: pointer;
  width: ${({$size}) => $size || '1rem'};
  height: ${({$size}) => $size || '1rem'};

  & path {
    fill: ${({$fillColor}) => $fillColor || 'currentColor'};
    stroke: ${({$stroke}) => $stroke || 'currentColor'};
    stroke-width: ${({$strokeWidth}) => $strokeWidth || 1};
  }
  &:hover path {
    stroke: ${({$hovercolor}) => $hovercolor || 'currentColor'};
  }
`;

const SvgComp = ({Icon, fillColor, strokeWidth, hovercolor, stroke, size}) => {
  return (
    <SvgIcon
      $fillColor={fillColor}
      $hovercolor={hovercolor}
      $stroke={stroke}
      $size={size}
      $strokeWidth={strokeWidth}
    >
      {Icon}
    </SvgIcon>
  );
};

export const ResponsiveImage = styled.div`
  background-image: ${({$image}) => `url(${$image})`};
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  height: 14rem;
  max-height: 22rem;
  transition: 0.3s ease-out all;
  cursor: pointer;
`;

export const StatusBadge = styled.span`
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: currentColor;
  }

  background: ${(props) => {
    switch (props.status.toLowerCase()) {
      case 'rejected':
        return '#FED7D7';
      case 'completed':
        return '#C6F6D5';
      case 'pending':
      case 'waiting':
        return '#FEEBC8';
      default:
        return '#EBF8FF';
    }
  }};
  color: ${(props) => {
    switch (props.status.toLowerCase()) {
      case 'rejected':
        return '#9B2C2C';
      case 'completed':
        return '#276749';
      case 'pending':
      case 'waiting':
        return '#9B4C1C';
      default:
        return '#2B6CB0';
    }
  }};
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
`;

export const Dialog = styled.div`
  position: relative;
  background-color: ${({bg}) => (bg ? bg : '#fff')};
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-inline: 0.6rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  width: 100%;
  max-width: ${({maxWidth}) => (maxWidth ? maxWidth : '42rem')};
  max-height: 90vh;

  ${({sx}) => sx};
`;

export const Divider = styled.div`
  width: 96%;
  height: 0.1rem;
  background-color: ${({theme}) => theme.gray[100]};
  margin-block: 0.8rem;
  ${({sx}) => sx};
`;

export const VerticalDivider = styled.div`
  width: 0.12rem;
  height: 90%;
  background-color: ${({bg, theme}) => bg ?? theme.bg[300]};
  margin: auto 1rem;

  @media only screen and (max-width: 53rem) {
    display: ${({display}) => display ?? 'none'};
  }
`;

export const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin-block: 1rem;
  span {
    font-size: 0.9rem;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    padding-inline: 1.2rem;
    color: ${({theme}) => theme.clr[300]};
  }
`;

export const VerifiedWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.25rem;
  width: 100%;

  h4 {
    font-weight: 500;
  }

  h5 {
    font-weight: 500;
  }

  img {
    margin-inline-start: 0.25rem;
    width: 1.55rem;
  }

  @media only screen and (max-width: 1024px) {
    h5,
    h4 {
      font-size: 1.3rem;
      line-height: 1.7rem;
      max-width: 90%;
      font-weight: 500;
    }

    img {
      margin-inline-start: 0.25rem;
      width: 1.15rem;
      margin-top: 0.35rem;
      margin-bottom: auto;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  width: 100%;
  max-width: 62rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0.2%;
  right: 1%;
  background: none;
  border: none;
  padding: 1rem;
  color: ${({theme}) => theme.clr[300]};
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    background-color: ${({theme}) => theme.bg[200]};
    border-radius: 99rem;
  }
`;

const CloseDialogWrapper = styled.div`
  position: absolute;
  top: 3%;
  right: 3%;

  img {
    width: 2.5rem;
    cursor: pointer;
    padding: 0.45rem;
    border-radius: 99rem;

    &:hover {
      background-color: ${({theme}) => theme.bg[200]};
    }
  }
`;

export const slideIn = keyframes`
  from {
    transform: translateY(-60px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const NoHistory = styled.div`
  text-align: center;
  padding-block: 2rem;
  width: 100%;
  color: ${({theme}) => theme.clr[200]};

  > p {
    font-weight: 400;
    line-height: 2rem;
    font-size: 1.75rem;
  }

  img {
    max-width: 100%;
    width: 28rem;
  }

  @media (max-width: 768px) {
    padding-block: 1rem;

    > p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    img {
      max-width: 80%;
    }
  }

  @media (max-width: 480px) {
    > p {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    img {
      max-width: 100%;
    }
  }
`;

export const FieldErrorText = styled.span`
  display: block;
  animation: ${slideIn} 0.3s ease forwards;
  color: ${({theme}) => theme.error.font};
  font-size: 0.9rem;
  z-index: 0;
`;

export const ChipTypeOne = styled.div`
  background-color: #e3f2fd;
  color: ${({theme}) => theme.clr[200]};
  padding: 5px;
  cursor: pointer;
  font-size: 0.75rem;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  padding-block: 0.35rem;
  padding-inline: 0.75rem;
  ${({sx}) => sx};
`;

export const RoundPicture = styled.img`
  width: ${({size}) => size ?? '9rem'};
  height: ${({size}) => size ?? '9rem'};

  border-radius: 50%;
  object-fit: cover;
  object-position: center;

  ${({sx}) => sx};
`;

export const ProfileWrapper = styled.section`
  width: 100%;
  background-color: ${({theme, $removeBg}) => !$removeBg && theme.bg[100]};

  border-radius: 0.5rem;
  padding: 1rem;

  @media only screen and (max-width: 55rem) {
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
`;

export const MeContainer = styled.div`
  border-radius: 0.5rem;
  padding: 1.8rem;

  @media only screen and (max-width: 55rem) {
    padding: 0.8rem;
  }
`;

export const MutipleFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;

  @media only screen and (max-width: 36em) {
    flex-direction: ${({notBreak}) => (notBreak ? 'row' : 'column')};
  }

  &:empty {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  width: ${({size}) => size ?? '9rem'};
  height: ${({size}) => size ?? '9rem'};
  ${({sx}) => sx};
`;

const ReviewWrapper = styled.p`
  display: flex;
  align-items: center;
  margin-inline-start: 0.2rem;
  color: ${({theme}) => theme.clr[200]};

  > span {
    color: #000;
    padding-inline-end: 0.4rem;
    font-weight: 400;
    @media only screen and (max-width: 53rem) {
      font-size: 0.95rem;
      line-height: 0.9;
    }
  }

  > p {
    font-size: ${({fontSize}) => fontSize ?? '1.2rem'};
    @media only screen and (max-width: 53rem) {
      font-size: 1rem;
      line-height: 0.9;
    }
  }
`;

export const BackgroundImage = styled.div`
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  height: 400px;
  position: relative;
  ${({sx}) => sx};
`;

export const FullDivider = styled(Divider)`
  width: 100%;
  ${({sx}) => sx}
`;

export const BackgroundImageOverlay = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.7)
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem;
  color: white;
`;

const ProgressBarWrapper = styled.div`
  height: 0.25rem;
  background-color: ${({theme}) => theme.bg[400]};
  overflow: hidden;
  width: 100%;

  @media only screen and (max-width: 1024px) {
    height: 0.2rem;
  }
`;

const Fill = styled.div`
  height: 100%;
  background-color: ${({step, theme}) =>
    step ? theme.secondary.default : 'none'};
  width: ${({step, steps}) => `${(step / steps) * 100}%`};
  transition:
    width 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
`;

const OrderColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  > span {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2.4rem;
    color: ${({theme}) => theme.clr[300]};
  }
  > p {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2.4rem;
  }
`;

export const ServiceTitle = styled.h5`
  color: ${({theme}) => theme.clr[300]};
  font-weight: 500;
  margin-bottom: 0.25rem;

  @media only screen and (max-width: 1024px) {
    font-weight: 500;
    margin-block: 3rem 0.75rem;
  }
`;

export const ServiceSubTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: ${({theme}) => theme.clr[200]};

  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3rem;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.15rem;
`;

export const FieldTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${({theme}) => theme.clr[300]};

  @media only screen and (max-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.4rem;
  }
`;

export const FieldSubtitle = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.45rem;
  color: ${({theme}) => theme.clr[200]};
  margin-block-start: 0.5rem;
  margin-block-end: auto;

  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
    font-weight: 400;
  }
`;

export const MobileTopStick = styled.div`
  position: inherit;
  background-color: transparent;
  padding: 0;
  border-bottom: none;
  border-top: none;

  @media only screen and (max-width: 1024px) {
    font-size: 1.45rem;
    position: fixed;
    background-color: #fff;
    padding: 1.45rem 1rem 1rem;
    top: -1%;
    right: 0;
    left: 0;
    z-index: 9999;
    border-bottom: 2px solid ${({theme}) => theme.bg[300]};
    border-top: 2px solid ${({theme}) => theme.bg[300]};
  }
`;

export const TitleAndBack = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > p {
    margin-block: 2rem;
    line-height: 2.2rem;
    font-size: 2rem;
    font-weight: 500;
    margin-inline-start: 1rem;
  }

  @media only screen and (max-width: 1024px) {
    > p {
      line-height: 2.2rem;
      font-size: 1.25rem;
      margin-block: 0rem;
      font-weight: 500;
      margin-inline: auto;
      padding-inline-end: 3rem;
      text-align: center;
    }
  }
`;

export const BackButton = ({title, sx}) => {
  const navigate = useNavigate();

  return (
    <TitleAndBack sx={sx}>
      <div onClick={() => navigate(-1)}>
        <SvgComp
          Icon={<ChevronLeft />}
          size='2rem'
          stroke={'#667085'}
          fillColor={'transparent'}
          strokeWidth={1.5}
        />
      </div>

      <p> {title} </p>
    </TitleAndBack>
  );
};

export const BioSectionTitle = styled.p`
  line-height: 1.75rem;
  font-size: 1.5rem;
  font-weight: 500;
  margin-block: 3rem 1rem;

  @media only screen and (max-width: 1024px) {
    line-height: 1.25rem;
    font-size: 1.25rem;
    font-weight: 500;
    margin-block: 3rem 1rem;
  }
`;

export const ChipsDesign = {
  Waiting: css`
    background: #f0af23;
    color: #fff;
  `,
  Interview: css`
    background: #2f80ed;
    color: #fff;
  `,
  Accepted: css`
    background: #27c281;
    color: #d4ffee;
  `,
  Rejected: css`
    background: #ea3b3b;
    color: #fff;
  `,
  Complete: css`
    background: #27c281;
    color: #fff;
  `
};

export const OrderStatus = styled.span`
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 0.25rem;
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  ${({hireStatus}) => ChipsDesign[hireStatus] || ChipsDesign.Waiting}
`;

export const FixBottom = styled.div`
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
    position: inherit;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding-top: 2rem;
    z-index: 3;
    transition: transform 0.2s ease-in-out;
    transform: translateY(${({$visible}) => ($visible ? '0' : '100%')});
  }
`;

export const BackgroundBlur = styled.div`
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 0.5rem;

    backdrop-filter: blur(5rem);
    -webkit-backdrop-filter: blur(5rem);

    mask-image: linear-gradient(
      to bottom,
      hsla(210, 100%, 45.1%, 0) 0%,
      hsla(210, 100%, 45.1%, 0.358) 9.5%,
      hsla(210, 100%, 45.1%, 0.527) 17.2%,
      hsla(210, 100%, 45.1%, 0.604) 23.6%,
      hsla(210, 100%, 45.1%, 0.787) 28.8%,
      hsla(210, 100%, 45.1%, 0.875) 33.3%,
      hsla(210, 100%, 45.1%, 0.964) 37.2%,
      hsl(210, 100%, 45.1%) 100%
    );
  }
`;

const ChildrenWrapper = styled.div`
  padding: 0.85rem 1rem;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0.85rem;
  max-width: 94%;
  margin: 0 auto 0.45rem;
  z-index: 9999;
  position: relative;
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
`;

export const StickyBottomContainer = ({children}) => {
  const bottomRef = useRef(null);
  const [visible, setVisible] = useState(true);
  const scrollTimeoutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Hide the component immediately when scrolling starts
      setVisible(false);

      // Clear any existing timeout
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      // Set a timeout to show the component after scrolling stops
      scrollTimeoutRef.current = setTimeout(() => {
        setVisible(true);
      }, 300); // Adjust delay as needed
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener and timeout on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);
  return (
    <FixBottom $visible={visible}>
      <BackgroundBlur />
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <div ref={bottomRef} />
    </FixBottom>
  );
};

export const FormatSchedule = ({obj, unit}) => {
  return Object.entries(obj).map(([key, value]) => (
    <OrderColumn key={key}>
      <span>{key}</span>
      <p>
        {value} {unit}
      </p>
    </OrderColumn>
  ));
};

export const ProgressBar = ({step, steps}) => {
  return (
    <ProgressBarWrapper>
      <Fill step={step} steps={steps} />
    </ProgressBarWrapper>
  );
};

export const Review = ({score, fontSize}) => {
  return (
    <ReviewWrapper fontSize={fontSize}>
      <span>&#9733;</span>
      <p> {score ?? 'New'}</p>
    </ReviewWrapper>
  );
};

export const CloseDialog = ({onClose}) => {
  return (
    <CloseDialogWrapper>
      <img src={x} alt='x' onClick={() => onClose(false)} loading='lazy' />
    </CloseDialogWrapper>
  );
};

export const RoundProfilePicture = ({src, alt, size, wraSx, picWra}) => {
  return (
    <ImageWrapper size={size} sx={wraSx}>
      <RoundPicture src={src} alt={alt} size={size} sx={picWra} />
    </ImageWrapper>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  border-radius: 999rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
  position: relative;
  margin-inline-start: 1rem;
`;

export const HeaderMenu = ({setCloseMenu, closeMenu, profileImage}) => {
  return (
    <MenuWrapper
      onClick={(e) => {
        e.stopPropagation();
        setCloseMenu((prev) => !prev);
      }}
    >
      <Hamburger closeMenu={closeMenu} />
      <RoundProfilePicture
        src={profileImage ?? profile}
        alt='profile'
        size={'2.2rem'}
      />
    </MenuWrapper>
  );
};

export const OrderDetails = styled.div`
  background-color: ${({theme}) => theme.bg[200]};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 1.5rem 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  position: relative;
  padding-top: 2rem;
`;

export const OrderTiming = styled.p`
  font-size: 0.9rem;
  margin-block-start: -0.75rem;
`;

export const OrderDetailItem = styled.p`
  margin: 0.5rem 0;
  font-size: 0.8rem;

  span {
    font-size: 0.8rem;
    color: #475467;
    font-weight: 500;
    display: block;
  }
`;

export default SvgComp;
