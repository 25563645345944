import {lazy} from 'react';

const Home = lazy(() => import('components/Home/index'));
const Authentication = lazy(() => import('components/Authentication'));
const AppErrorBoundary = lazy(() => import('./AppErrorBoundary'));
const ProtectedRoutes = lazy(() => import('./ProtectedRoutes'));
const Error403 = lazy(() => import('./errorPages/Error403'));
const IfAuthUnAuth = lazy(() => import('./IfAuthUnAuth'));
const UserProfile = lazy(() => import('components/AccountProfile/UserProfile'));
const ServiceAccount = lazy(
  () => import('components/AccountProfile/ServiceAccount/ServiceAccount')
);
const AllBusiness = lazy(() => import('components/marketplace/AllBusiness'));

const Article = lazy(() => import('components/articles/Article'));
const ReadArticle = lazy(() => import('components/articles/ReadArticle'));
const AboutCompany = lazy(() => import('components/aboutCompany.js'));

const About = lazy(() => import('components/about/About'));

const StartChefOffer = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/chef/StartChefOffer'
    )
);

const StartCeilingPackage = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/ceilingSettler/StartCeilingPackage'
    )
);

const VerifyOtp = lazy(() => import('components/Authentication/otp/VerifyOtp'));
const Mobile = lazy(() => import('components/Mobile'));
const Terms = lazy(() => import('components/Terms and Services/Terms'));
const HelpCenter = lazy(
  () => import('components/Terms and Services/HelpCenter')
);

const SiteMap = lazy(() => import('components/sitemap'));

const FormSubmitted = lazy(
  () => import('components/orders/painter/FormSubmitted')
);

const Hire = lazy(() => import('components/WorkerProfile/hire'));

const PackageCheckout = lazy(
  () => import('components/WorkerProfile/order/PackageCheckout')
);

const QuickAuth = lazy(
  () => import('components/Authentication/quickAuth/QuickAuthForm')
);

const ResetPassword = lazy(
  () => import('components/Authentication/forget/ResetPassword')
);

const ForgetPassword = lazy(
  () => import('components/Authentication/forget/ForgetPassword')
);
const ActivateEmail = lazy(
  () => import('components/Authentication/forget/EmailValidateConfirm')
);

const EmailSentConfirm = lazy(
  () => import('components/Authentication/forget/EmailSentConfirm')
);

const WorkerAccountDetail = lazy(
  () => import('components/WorkerProfile/WorkerAccountDetail')
);

const StartWorkerForm = lazy(
  () => import('components/StartWorker/StartWorkerForm')
);

const BaseUserForm = lazy(
  () => import('components/StartWorker/base-account/index')
);

const AddNewRoom = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/hotelingResort/AddNewRoom'
    )
);

const StartServiceForm = lazy(
  () => import('components/StartWorker/StartServiceForm')
);

const WelcomeOnBoard = lazy(
  () => import('components/StartWorker/WelcomeOnBoard')
);

const TourGuidePackages = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/touristGuide/PackageDetail'
    )
);

const RoomDetails = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/hotelingResort/RoomDetails'
    )
);

const HotelDetails = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/hotelingResort/HotelDetails'
    )
);

const AllRooms = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/hotelingResort/AllRooms'
    )
);

const Bookings = lazy(
  () =>
    import(
      'components/AccountProfile/ServiceAccount/services/hotelingResort/Bookings'
    )
);

const FAQ = lazy(() => import('components/about/FAQ'));
const CsChat = lazy(() => import('cS/Chat'));

export const routes = [
  {
    path: '/',
    element: <Home />,
    errorElement: <AppErrorBoundary />
  },

  {
    element: <ProtectedRoutes />,
    children: [
      {path: '/user-account-settings', element: <UserProfile />},
      {path: '/service-account-settings', element: <ServiceAccount />},
      {path: '/service-package-settings', element: <ServiceAccount />},
      {path: '/chef-start-package', element: <StartChefOffer />},
      {path: '/ceiling-start-package', element: <StartCeilingPackage />},
      {path: '/mobile', element: <Mobile />},

      {
        path: '/user-account-details',
        element: <BaseUserForm />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/hire/*',
        element: <Hire />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/order/*',
        element: <PackageCheckout />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/steps',
        element: <StartWorkerForm />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/service-details',
        element: <StartServiceForm />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/order-success',
        element: <FormSubmitted />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/welcome-on-board',
        element: <WelcomeOnBoard />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/add-new-room',
        element: <AddNewRoom />,
        errorElement: <AppErrorBoundary />
      },

      {
        path: '/chat',
        element: <CsChat />,
        errorElement: <AppErrorBoundary />
      }
    ]
  },

  {
    element: <IfAuthUnAuth />,
    children: [
      {path: '/on-boarding', element: <Authentication />},
      {path: '/quick-on-boarding', element: <QuickAuth />},
      {path: '/forget-password', element: <ForgetPassword />},
      {path: '/email-sent-confirm/', element: <EmailSentConfirm />},
      {path: '/otp-verfication', element: <VerifyOtp />}
    ]
  },

  {
    path: 'worker-accounts-detail/*',
    element: <WorkerAccountDetail />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/all-business/*',
    element: <AllBusiness />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/password/reset/confirm/*',
    element: <ResetPassword />,
    errorElement: <AppErrorBoundary />
  },
  {
    path: '/activate/*',
    element: <ActivateEmail />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/faq/',
    element: <FAQ />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/article/*',
    element: <Article />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/read-article/:uuid',
    element: <ReadArticle />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/about',
    element: <About />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/about-company',
    element: <AboutCompany />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/packages/:uuid',
    element: <TourGuidePackages />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/room-details/:uuid',
    element: <RoomDetails />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/place-details/:uuid',
    element: <HotelDetails />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/terms',
    element: <Terms />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/customer-support',
    element: <HelpCenter />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/site-map',
    element: <SiteMap />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/all-rooms',
    element: <AllRooms />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '/bookings',
    element: <Bookings />,
    errorElement: <AppErrorBoundary />
  },

  {
    path: '*',
    element: <Error403 />
  }
];
