import {useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

export default function serialize(obj) {
  var str = [];

  for (var p in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, p) &&
      obj[p] !== undefined &&
      obj[p] !== null
    ) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
}

export function queryMerge(obj, existingQueryString = '') {
  const existingParams = new URLSearchParams(existingQueryString);
  const mergedParams = new URLSearchParams(existingParams);

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      mergedParams.set(key, obj[key]);
    }
  }

  return mergedParams.toString();
}

export const useUrlState = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setUrlParams = useCallback(
    (params) => {
      const currentParams = location.search;
      const newParams = queryMerge(params, currentParams);

      navigate(`${location.pathname}?${newParams}`, {replace: true});
    },
    [location, navigate]
  );

  const setUrlParam = useCallback(
    (key, value) => {
      const currentParams = location.search;
      const newParams =
        value === null || value === undefined || value === ''
          ? new URLSearchParams(currentParams)
          : new URLSearchParams(queryMerge({[key]: value}, currentParams));

      navigate(`${location.pathname}?${newParams.toString()}`, {
        replace: true
      });
    },
    [location, navigate]
  );

  const getUrlParam = useCallback(
    (key) => {
      const searchParams = new URLSearchParams(location.search);
      const param = searchParams.get(key);
      if (param === 'null') return null;
      if (param === 'undefined') return undefined;
      return searchParams.get(key);
    },
    [location]
  );

  return {setUrlParam, getUrlParam, setUrlParams};
};
