import extractProfession from './extractProfession';
import {FindService} from './findService';

export default function initialFormat(worker) {
  const service = FindService(worker?.service_category?.[0]);
  const initailForm = {
    service_category: worker?.service_category ?? [],
    business_phone_number: worker?.business_phone_number ?? '',
    address: worker?.address ?? '',
    national_id_card_number: worker?.national_id_card_number ?? '',
    skills: worker?.skills ?? '',
    certifications: worker?.certifications ?? '',
    biography: worker?.biography ?? '',
    service_details: {}
  };

  if (!service || !worker?.service_category?.[0]) return initailForm;

  // Service-specific mapping
  const serviceDataMap = {
    'Hoteling & Resort Service': () => {
      const resort = worker?.hotel_resort?.[0];
      return {
        id: resort?.id,
        property_name: resort?.property_name ?? '',
        property_type: resort?.property_type ?? '',
        environment: resort?.environment ?? '',
        security: resort?.security ?? '',
        amenities: resort?.amenities ?? '',
        bedrooms: resort?.bedrooms ?? '',
        bathrooms: resort?.bathrooms ?? '',
        languages: resort?.languages ?? '',
        images: resort?.hotel_images_details ?? [],
        video: resort?.video ?? '',
        pet_policy: resort?.pet_policy ?? '',
        restricted_policies: resort?.restricted_policies ?? '',
        house_rules: resort?.house_rules ?? '',
        check_out_time: resort?.check_out_time ?? '',
        check_in_time: resort?.check_in_time ?? '',
        nearby_attractions: resort?.nearby_attractions ?? '',
        distance_from_airport: resort?.distance_from_airport ?? ''
      };
    },

    'Painting Service': () => {
      const painter = worker?.painter?.[0];
      return {
        years_experience: painter?.years_experience ?? '',
        areas_of_expertise: painter?.areas_of_expertise ?? '',
        under_coat_labor_charges: painter?.under_coat_labor_charges ?? '',
        wall_painting_labor_charges: painter?.wall_painting_labor_charges ?? '',
        wood_painting_labor_charges: painter?.wood_painting_labor_charges ?? '',
        wood_polishing_labor_charges:
          painter?.wood_polishing_labor_charges ?? '',
        texture_labor_charges: painter?.texture_labor_charges ?? {},
        charge_type: 'ft²',
        charge: painter?.charge ?? '',
        material_include: painter?.material_include ?? '',
        under_coat_with_material: painter?.under_coat_with_material ?? null,
        wall_painting_with_material:
          painter?.wall_painting_with_material ?? null,
        wood_painting_with_material:
          painter?.wood_painting_with_material ?? null,
        wood_polishing_with_material:
          painter?.wood_polishing_with_material ?? null,
        paint_name: painter?.paint_name ?? null,
        texture_with_material: painter?.texture_with_material ?? {}
      };
    },

    'Tour Guide Service': () => {
      const tourGuide = worker?.tour_guide?.[0];
      return {
        years_experience: tourGuide?.years_experience ?? '',
        charge: tourGuide?.charge ?? '',
        charge_type: tourGuide?.charge_type ?? 'hour',
        education: tourGuide?.education ?? '',
        areas_of_expertise: tourGuide?.areas_of_expertise ?? '',
        languages: tourGuide?.languages ?? '',
        offer_group_trip: tourGuide?.offer_group_trip ?? false
      };
    },

    'Attorney Service': () => {
      const attorney = worker?.attorney?.[0];
      return {
        firm_name: attorney?.firm_name ?? '',
        areas_of_expertise: attorney?.areas_of_expertise ?? '',
        jurisdictions: attorney?.jurisdictions ?? '',
        education: attorney?.education ?? '',
        years_experience: attorney?.years_experience ?? '',
        charge: attorney?.charge ?? '',
        charge_type: attorney?.charge_type ?? 'hour',
        languages: attorney?.languages ?? ''
      };
    },

    'Quran Teacher Service': () => {
      const quranTeacher = worker?.quran_teacher?.[0];
      return {
        areas_of_expertise: quranTeacher?.areas_of_expertise ?? '',
        education: quranTeacher?.education ?? '',
        years_experience: quranTeacher?.years_experience ?? '',
        charge: quranTeacher?.charge ?? '',
        languages: quranTeacher?.languages ?? '',
        teaching_methods: quranTeacher?.teaching_methods ?? '',
        virtual_classes: quranTeacher?.virtual_classes ?? false,
        offer_package: quranTeacher?.offer_package ?? false
      };
    },

    'Chef Service': () => {
      const chef = worker?.chef?.[0];
      return {
        charge: chef?.charge ?? '',
        areas_of_expertise: chef?.areas_of_expertise ?? '',
        languages: chef?.languages ?? '',
        years_experience: chef?.years_experience ?? '',
        offering_dishes: chef?.offering_dishes ?? '',
        chef_offers: chef?.chef_offers ?? ''
      };
    },

    'Marble & Tile Specialist': () => {
      const marbleSpecialist = worker?.marble_specialist?.[0];
      return {
        years_experience: marbleSpecialist?.years_experience ?? '',
        areas_of_expertise: marbleSpecialist?.areas_of_expertise ?? '',
        stone_types: marbleSpecialist?.stone_types ?? '',
        charge: marbleSpecialist?.charge ?? '',
        charge_type: marbleSpecialist?.charge_type ?? 'hour',
        special_designs_and_inlays:
          marbleSpecialist?.special_designs_and_inlays ?? {}
      };
    },

    'Ceiling Specialist': () => {
      const ceilingSpecialist = worker?.ceiling_specialist?.[0];
      return {
        years_experience: ceilingSpecialist?.years_experience ?? '',
        charge: ceilingSpecialist?.charge ?? '',
        charge_type: ceilingSpecialist?.charge_type ?? 'hour',
        name: ceilingSpecialist?.name ?? ''
      };
    },

    'Travels & Tours Agency': () => {
      const travelTourAgency = worker?.travel_tour_agency?.[0];
      return {
        years_experience: travelTourAgency?.years_experience ?? '',
        areas_of_expertise: travelTourAgency?.areas_of_expertise ?? ''
      };
    }
  };

  // Dynamically load service details if service is recognized
  if (serviceDataMap[service.name]) {
    initailForm.service_details = serviceDataMap[service.name]();
  } else {
    console.log('No matching service found for:', service.name);
  }

  return initailForm;
}

export function serviceForm(worker) {
  const prof = extractProfession(worker);
  const initailForm = {
    Painter: {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        material_include: prof?.material_include ?? '',
        charge: prof?.charge ?? ''
      },
      labour: {
        under_coat_labor_charges: prof?.under_coat_labor_charges ?? '',
        wall_painting_labor_charges: prof?.wall_painting_labor_charges ?? '',
        wood_painting_labor_charges: prof?.wood_painting_labor_charges ?? '',
        wood_polishing_labor_charges: prof?.wood_polishing_labor_charges ?? ''
      },
      material: {
        paint_name: prof?.paint_name ?? '',
        wall_painting_with_material: prof?.wall_painting_with_material ?? '',
        under_coat_with_material: prof?.under_coat_with_material ?? '',
        wood_painting_with_material: prof?.wood_painting_with_material ?? '',
        wood_polishing_with_material: prof?.wood_polishing_with_material ?? ''
      }
    },
    Attorney: {
      about: {
        firm_name: prof?.firm_name ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        jurisdictions: prof?.jurisdictions ?? '',
        education: prof?.education ?? '',
        years_experience: prof?.years_experience ?? '',
        languages: prof?.languages ?? ''
      },

      pricing: {
        charge_type: prof?.charge_type ?? 'hour'
      },

      education: {
        education: ''
      }
    },

    'Quran Teacher': {
      about: {
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        years_experience: prof?.years_experience ?? '',
        languages: prof?.languages ?? '',
        teaching_methods: prof?.teaching_methods ?? '',
        virtual_classes: prof?.virtual_classes ?? false,
        offer_package: prof?.offer_package ?? false
      },

      education: {
        education: ''
      },
      pricing: {
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      }
    },

    'Marble & Tile Specialist': {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      },

      type: {
        name: ''
      },

      fitting_pricing: {
        floor_fitting: '',
        wall_cladding: '',
        staircase_fitting: '',
        countertops: '',
        frontend_elevation: ''
      },

      edging_pricing: {
        miter_edge: '',
        ogee_on_eased_edge: '',
        ogee_on_half_round_edge: '',
        ogee_on_inverted_ogee: '',
        ogee_on_ogee_edge: '',
        ogee_on_ogee_shifted_edge: '',
        ogee_on_quarter_round_edge: '',
        quarter_round_on_half_round_edge: '',
        triple_pencil_on_half_round_edge: '',
        half_bevel_on_eased: '',
        half_round_on_eased: '',
        eased_on_eased: '',
        chiseled: '',
        triple_pencil: '',
        cove_ogee: '',
        ogee: '',
        half_round: '',
        half_bevel: '',
        quarter_round: '',
        ease: ''
      },

      polishing: {
        standard_polishing: '',
        high_gloss_polishing: '',
        honing_polishing: '',
        matte_polishing: '',
        satin_polishing: '',
        brushed_polishing: '',
        tumbled_polishing: '',
        leathered_polishing: '',
        glazed_polishing: '',
        bush_hammered_polishing: ''
      },

      special_designs_and_inlays: {}
    },

    'Tour Guide': {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? '',
        languages: prof?.languages ?? ''
      },

      education: {
        education: ''
      },

      pricing: {
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      }
    },

    Chef: {
      charges: {
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      },
      about: {
        years_experience: prof?.years_experience ?? '',
        languages: prof?.languages ?? ''
      },

      expertise: {
        areas_of_expertise: prof?.expertise ?? ''
      },

      dishes: {
        offering_dishes: {}
      }
    },

    'Ceiling Specialist': {
      about: {
        years_experience: prof?.years_experience ?? '',
        charge: prof?.charge ?? '',
        charge_type: prof?.charge_type ?? 'hour'
      }
    },

    'Travels & Tours Agency': {
      about: {
        years_experience: prof?.years_experience ?? '',
        areas_of_expertise: prof?.areas_of_expertise ?? ''
      }
    },

    'Hoteling & Resort Service': {
      about: {
        property_name: prof?.property_name ?? '',
        property_type: prof?.property_type ?? '',
        environment: prof?.environment ?? '',
        security: prof?.security ?? '',
        amenities: prof?.amenities ?? '',
        bedrooms: prof?.bedrooms ?? '',
        bathrooms: prof?.bathrooms ?? ''
      },
      policies: {
        pet_policy: prof?.pet_policy ?? '',
        restricted_policies: prof?.restricted_policies ?? '',
        house_rules: prof?.house_rules ?? '',
        check_out_time: prof?.check_out_time ?? '',
        check_in_time: prof?.check_in_time ?? ''
      },

      area: {
        nearby_attractions: prof?.nearby_attractions ?? '',
        distance_from_airport: prof?.distance_from_airport ?? ''
      }
    }
  };

  return initailForm;
}
